<template>
  <v-card>
    <v-card-title>
      <span class="headline">Nuevo año</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="defaultAnual.fechaAnual"
              label="Año"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="defaultAnual.cantidadPeriodos"
              label="Peridos"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogAnual = false">
        Cancelar
      </v-btn>
      <v-btn color="blue darken-1" text @click="saveAnual"> Guardar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
  methods: {
    ...mapActions(["RegistrarAnual"]),
    ...mapMutations(["ADD_ANUALES"]),
  },
};
</script>