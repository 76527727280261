<template>
  <v-card>
    <v-toolbar prominent max-height="150px">
      <v-container>
        <v-row>
          <v-col>
            <v-toolbar-title>Configuración de grupo </v-toolbar-title>
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-col cols="6">
            <v-autocomplete
              :items="anual"
              hide-no-data
              hide-details
              solo
              item-text="fechaAnual"
              item-value="id"
              label="Seleccione un año"
              v-model="periodoAnualId"
              @change="cambioAnual"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-container v-if="isAnual">
      <v-row>
        <v-card-title>Director de grupo</v-card-title>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="directorGrupo"
            label="Seleccione un director de grupo"
            item-text="nombre"
            item-value="id"
            :items="docentes"
            @change="cambioDirectorGrupo"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              Alumnos actuales
              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon @click="dialogAlunmos = true"
                  >mdi-account-multiple-plus-outline</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon @click="dialogTransferir = true"
                  >mdi-cog-transfer-outline</v-icon
                >
              </v-btn>
            </v-card-title>
          </v-card>

          <download-excel :data="ListaExcel"> Descargar lista </download-excel>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="alumnosGrupo"
            item-key="name"
            class="elevation-1"
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogAlunmos">
      <v-card height="500px" min-height="500px">
        <v-card-title>Seleccione los alumnos que desea registrar</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="alumnosSelecionados"
                  :items="alumnoList"
                  deletable-chips
                  multiple
                  small-chips
                  label="Alumnos"
                  item-text="nombres"
                  item-value="id"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAlunmos = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveAlumnos">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["grupo"],
  data: () => ({
    alumnosSelecionados: [],
    alumnosGrupo: [],
    dialogAlunmos: false,
    dialogTransferir: false,
    periodoAnualId: 0,
    dialogAnual: false,
    directorGrupo: 0,
    alumnos: [],
    docentes: [],
    selected: [],
    headers: [
      {
        text: "Apellidos alumno",
        value: "apellidos",
      },
      {
        text: "Nombre alumno",
        align: "start",
        value: "nombres",
      },
    ],
  }),
  computed: {
    ...mapGetters(["anual"]),
    isAnual() {
      return this.periodoAnualId > 0;
    },
    alumnoList() {
      return this.alumnos.map((item) => {
        var alumno = {
          id: item.id,
          nombres: `${item.nombres} ${item.apellidos}`,
        };
        return alumno;
      });
    },
    ListaExcel() {
      return this.alumnosGrupo.map((item) => {
        return {
          apellidos: item.apellidos,
          nombres: item.nombres,
        };
      });
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "GetAnuales",
      "GetAlumnos",
      "GetDocentes",
      "GetDirectorGrupo",
      "GetAlumnosGrupo",
      "UpdateDirector",
      "RegistarAlumnosGrupos",
    ]),
    initialize() {
      this.GetDocentes().then((x) => (this.docentes = x.data));
      this.GetAlumnos().then((x) => (this.alumnos = x.data));
      this.GetAnuales();
    },
    saveAlumnos() {
      let alumnos = this.alumnosSelecionados.map((id) => {
        return {
          estudianteId: id,
          grupoId: this.grupo.id,
          periodoAnualId: this.periodoAnualId,
        };
      });

      this.RegistarAlumnosGrupos(alumnos).then(
        (x) => (this.alumnosGrupo = x.data.grupoEstudiantes)
      );
    },
    close() {},
    remove(item) {
      const index = this.alumnosGrupo.indexOf(item.id);
      if (index >= 0) this.alumnosGrupo.splice(index, 1);
    },
    cambioAnual() {
      var parametros = { grupo: this.grupo.id, anual: this.periodoAnualId };
      this.GetDirectorGrupo(parametros).then((x) => {
        if (!!x.data) {
          this.directorGrupo = x.data.docenteId;
        } else {
          this.directorGrupo = 0;
        }
      });

      this.GetAlumnosGrupo(parametros).then((x) => {
        if (x.data.length > 0) {
          this.alumnosGrupo = x.data;
        } else {
          this.alumnosGrupo = [];
        }
      });
    },
    cambioDirectorGrupo() {
      var directorGrupo = {
        docenteId: this.directorGrupo,
        periodoAnualId: this.periodoAnualId,
        grupoId: this.grupo.id,
      };

      this.UpdateDirector(directorGrupo);
    },
  },
  watch: {
    grupo() {
      this.alumnosSelecionados = [];
      this.alumnosGrupo = [];
      this.periodoAnualId = 0;
      this.directorGrupo = 0;

      this.selected = [];
    },
  },
};
</script>