<template>
    <Grupos></Grupos>
</template>
<script>
import Grupos from "@/components/Grupos.vue"
export default {
    components:{
        Grupos
    }
    
}
</script>