<template>
  <v-card>
    <v-card-title>
      <span class="headline">Nuevo curso</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="defaultCurso.nombre"
              label="Curso"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogCurso = false">
        Cancelar
      </v-btn>
      <v-btn color="blue darken-1" text @click="saveCursos"> Guardar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data: () => ({
    defaultCurso: {
      nombre: "",
    },
  }),
  methods: {
    ...mapActions(["RegistrarCursos"]),
    ...mapMutations(["ADD_CURSOS"]),
    saveCursos() {
      this.RegistrarCursos(this.defaultCurso).then((x) => {
        const respuesta = x.data;
        if (!!respuesta) {
          this.ADD_CURSOS(respuesta);
        }
      });
    },
  },
};
</script>