<template>
  <v-data-table
    :headers="headers"
    :items="grupos"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title> Administración de grupos </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
          <v-dialog persistent v-model="dialog" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Adicionar
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Nuevo grupo</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        :items="sede"
                        item-text="nombre"
                        item-value="id"
                        label="Sede"
                        v-model="editedItem.sedeId"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.nombre"
                        label="nombre"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="editedItem.cursoId"
                        :items="getCursos"
                        item-text="nombre"
                        item-value="id"
                        label="Curso"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--dialog cursos-->
          <v-dialog persistent v-model="dialogCurso" max-width="500">
            <Cursos></Cursos>
          </v-dialog>
          <v-dialog v-model="dialogAnual" max-width="290">
            <Anual></Anual>
          </v-dialog>
          <v-dialog
            v-model="dialogRegistro"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
          >
            <v-card tile>
              <v-toolbar flat dark color="primary" max-height="50px">
                <v-btn icon dark small top @click="dialogRegistro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <RegistroGrupos :grupo="editedItem"></RegistroGrupos>
            </v-card>
          </v-dialog>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small class="mr-2" @click="estudiantes(item)">
        mdi-account-multiple-outline</v-icon
      >
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Cursos from "@/components/Cursos.vue";
import Anual from "@/components/Anual.vue";
import RegistroGrupos from "@/components/RegistroGrupos.vue";
export default {
  components: {
    Cursos,
    Anual,
    RegistroGrupos,
  },
  computed: {
    ...mapGetters(["getCursos", "anual", "sede"]),
  },
  data: () => ({
    dialog: false,
    dialogAnual: false,
    dialogCurso: false,
    dialogRegistro: false,
    grupos: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      sedeId: 0,
      nombre: "",
      cursoId: 0,
    },
    defaultItem: {
      id: 0,
      sedeId: 0,
      nombre: "",
      cursoId: 0,
    },
    headers: [
      { text: "Grupo", value: "nombre" },
      { text: "Curso", value: "nombreCurso" },
      { text: "Sede", value: "nombreSede" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.initialize();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions([
      "GetGrupos",
      "GetCursos",
      "GetAnuales",
      "RegistrarGrupos",
      "GetSedes",
      "UpdateGrupo",
      "ModificarMensaje",
    ]),
    initialize() {
      this.GetSedes();
      this.GetCursos();
      this.GetAnuales();
      this.GetGrupos().then((x) => (this.grupos = x.data));
    },
    save() {
      if (this.editedIndex > -1) {
        //editar
        let index = this.editedIndex;
        var editItem = this.editedItem;
        this.UpdateGrupo(editItem).then((x) => {
          Object.assign(this.grupos[index], editItem);
          this.ModificarMensaje({ texto: "Actualización Exitoso!" });
        });
      } else {
        //nuevo
        this.RegistrarGrupos(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.grupos.push(respuesta);
            this.close();
            this.ModificarMensaje({ texto: "Registro Exitoso!" });
          }
        });
      }
    },
    editItem(item) {
      this.editedIndex = this.grupos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    estudiantes(item) {
      this.editedIndex = this.grupos.indexOf(item);
      this.dialogRegistro = true;
      this.editedItem = Object.assign({}, item);
    },
  },
};
</script>